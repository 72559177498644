<template>
    <div class="session-wrapper">
        <div class="session-left"></div>
        <div class="session-right text-center">
            <div class="session-table-cell">
                <div class="session-content">
                    <div class="mb-6 flex justify-center">
                        <img :src="meta[domain].logo" height="45" />
                    </div>
                    <v-form class="mb-5">
                        <v-text-field
                            label="Username or Email"
                            v-model="form.username"
                            :error="form.errors.has('username')"
                            :error-messages="form.errors.get('username')"
                            @keydown="form.errors.clear('username')"
                            :autofocus="form.username === null"
                            required
                        ></v-text-field>
                        <v-text-field
                            label="Password"
                            v-model="form.password"
                            :error="form.errors.has('password')"
                            :error-messages="form.errors.get('password')"
                            @keydown="form.errors.clear('password')"
                            type="password"
                            :autofocus="form.username !== null"
                            required
                        ></v-text-field>
                        <div class="d-flex mb-2 align-center justify-space-between">
                            <v-checkbox
                                color="primary"
                                label="Remember me"
                                v-model="form.remember"
                            ></v-checkbox>
                            <router-link to="/forgot-password">Forgot Password?</router-link>
                        </div>
                        <div>
                            <v-btn
                                @click="login"
                                block
                                color="primary"
                                class="mb-2"
                                :loading="loggingInStandard"
                                :disabled="loggingInOffice365"
                                >Log in</v-btn
                            >
                            <v-divider class="tw-my-4" />
                            <office365-login-button
                                @success="loginUsingOffice365"
                                :disabled="loggingInStandard"
                                :loading="loggingInOffice365"
                            >
                                <v-icon left dark>mdi-microsoft-office</v-icon>
                                Log in with Office 365
                            </office365-login-button>
                        </div>
                    </v-form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import Form from "@/utils/form";
import Http from "@/utils/httpClient";
import Office365LoginButton from "@/components/Office365LoginButton.vue";

export default {
    components: {
        Office365LoginButton
    },
    data() {
        return {
            loggingInStandard: false,
            loggingInOffice365: false,
            form: new Form({
                username: null,
                password: null,
                remember: false
            })
        };
    },
    computed: {
        ...mapGetters(["meta", "domain"])
    },
    mounted() {
        if (this.$route.query.token) {
            this.loginViaToken(this.$route.query.token);
        }

        this.form.set({
            username: this.$route.query.email
        });
    },
    methods: {
        login() {
            this.loggingInStandard = true;
            this.form.errors.clear();
            Http.post("login", this.form.payload())
                .then(() => {
                    this.$router.push({ name: "dashboard" }, () => {
                        this.loggingInStandard = false;
                    });
                })
                .catch(({ response }) => {
                    this.loggingInStandard = false;
                    this.form.errors.record(response.data);
                });
        },
        loginUsingOffice365(data) {
            this.loggingInOffice365 = true;

            Http.post("login/office365", {
                token: data.accessToken
            })
                .then(() => {
                    this.$router.push({ name: "dashboard" }, () => {
                        this.loggingInOffice365 = false;
                    });
                })
                .catch(({ response }) => {
                    this.loggingInOffice365 = false;
                    this.$root.$snackbar.open({
                        text: response.data.errors.account[0],
                        type: "error",
                        timeout: -1
                    });
                });
        },
        loginViaToken(token) {
            this.$root.$loading.open({ text: "Logging in via token" });
            Http.get("csrf-cookie").then(() => {
                Http.post("login/token", {
                    token
                })
                    .then(() => {
                        this.$router.push({ name: "dashboard" }, () => {
                            this.$root.$loading.close();
                        });
                    })
                    .catch(() => this.$root.$loading.close());
            });
        }
    }
};
</script>
